<template>
   <v-container fluid>
    <TitleBar :title="'ประกาศ'" :subtitle="'เจ้าหน้าที่ ผู้ป่วย และกลุ่มติดตามข้อมูล'">
      <template slot="action">
            <v-btn x-large color="primary" @click="createItem()"><v-icon>mdi-plus</v-icon><span class="text-title-2">สร้างประกาศ</span></v-btn>
      </template>
    </TitleBar>
      <FilterBar>
      <template v-slot:left>
         <v-col cols="12" md="3" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,อื่นๆ"/>
         </v-col>
           <v-col cols="12" md="3" >
                <Select :title="'ประเภท'" :placeholder="'ประเภท'" :items="newsTypeList" v-model="newsType" item-text="name" item-value="id"/>
              </v-col>
            <v-col cols="12" md="3" >
                <Select :title="'ส่งถึง'" :placeholder="'ส่งถึง'" :items="receiverList" v-model="receiver" item-text="name" item-value="id"/>
              </v-col>
                 <v-col cols="12" md="3" >
                <Select :title="'กลุ่ม'" :disabled="receiver !== 'group_patient'" :placeholder="'กลุ่ม'" :items="groupList" v-model="groupId" item-text="groupName" item-value="id"/>
              </v-col>
      </template>
      <template v-slot:right>
      </template>
    </FilterBar>
    <DataTable :headers="headers" :loading="dataLoading" :search="search" :items="items" :options.sync="options" :server-items-length="totalItems">
       <template v-slot:item.action="{ item }">
        <v-btn icon @click="editeItem(item)">
        <v-icon >mdi-pencil</v-icon></v-btn>
       </template>
        <template v-slot:item.delete="{ item }">
         <v-btn icon @click="deleteItem(item)">
          <v-icon >mdi-delete</v-icon>
        </v-btn>
       </template>
        <template v-slot:item.createDate="{item}">
         {{$dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
       </template>
    </DataTable>
      <v-dialog v-model="announcementDialog" width="450" persistent scrollable >
      <CreateAnnouncementCard @onClose="announcementDialog=false" @onSubmit="postAnnouncement" @onUpdate="patchAnnouncement" :action="'บันทึก'" v-model="announcementItem" :key="announcementDialog" :edit="mode" :receiverList="receiverList" :newsTypeList="newsTypeList" :groupList="groupList"/>
    </v-dialog>
      <v-dialog v-model="deleteDialog" width="450" persistent scrollable >
      <ConfirmDeleteCard @onClose="deleteDialog=false" @onConfirm="deleteAnnouncement" :key="deleteDialog" :action="'ลบ'" :title="'คุณต้องการลบรายการนี้หรือไม่'" :subtitle="'หากท่านลบรายการนี้แล้วจะไม่สามารถกู้คืนได้อีก'"/>
    </v-dialog>
     <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import TextField from '@/components/common/TextField'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import Select from '@/components/common/Select'
import { getMasterNewsReceiver, getMasterNewsType, getNews, getGroup, postNews, deleteNews, patchNews } from '@/api/'

export default {
  components: {
    TitleBar,
    TextField,
    FilterBar,
    DataTable,
    Select,
    CreateAnnouncementCard: () => import('@/components/announcement/CreateAnnouncementCard'),
    ConfirmDeleteCard: () => import('@/components/common/ConfirmDeleteCard')
    // Select
  },
  data () {
    return {
      items: [],
      headers: [
        { text: 'หัวข้อ', value: 'title', class: 'primary lighten-5' },
        { text: 'เนื้อหา', value: 'body', class: 'primary lighten-5' },
        { text: 'เมื่อ', value: 'createDate', class: 'primary lighten-5' },
        { text: 'โดย', value: 'createByUserName', class: 'primary lighten-5' },
        { text: 'แก้ไข', value: 'action', class: 'primary lighten-5' },
        { text: 'ลบ', value: 'delete', class: 'primary lighten-5' }
      ],
      loading: false,
      dataLoading: false,
      search: null,
      announcementDialog: false,
      announcementItem: { isPin: false },
      receiverList: [],
      receiver: null,
      newsTypeList: [],
      newsType: null,
      groupId: null,
      groupList: [],
      mode: false,
      deleteDialog: false,
      options: {},
      totalItems: 0,
      filter: false
    }
  },
  watch: {
    groupId (value) {
      if (this.filter && value) {
        this.getNews()
      }
    },
    newsType (value) {
      if (this.filter && value) {
        if (this.receiver !== 'group_patient') this.groupId = null
        this.getNews()
      }
    },
    receiver (value) {
      if (this.filter && value) {
        if (this.receiver !== 'group_patient') this.groupId = null
        this.getNews()
      }
    }
  },
  methods: {
    deleteItem (item) {
      this.announcementItem = item
      this.deleteDialog = true
    },
    createItem () {
      this.mode = false
      this.announcementItem = { isPin: false }
      this.announcementDialog = true
    },
    editeItem (item) {
      this.mode = true
      this.announcementItem = item
      this.announcementDialog = true
    },
    postAnnouncement () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      postNews({ tenantId: tenant.tenantId, siteId: site.siteId, title: this.announcementItem.title, body: this.announcementItem.body, bannerName: this.announcementItem.bannerName, newsType: this.announcementItem.newsType, newsReceiver: this.announcementItem.newsReceiver, groupId: this.announcementItem.groupId, isPin: this.announcementItem.isPin }, message => {
        if (message.data.code === 1) {
          this.announcementDialog = false
          this.getNews()
        }
      }, error => {
        console.log(error)
      })
    },
    patchAnnouncement () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      patchNews({ tenantId: tenant.tenantId, siteId: site.siteId, id: this.announcementItem.id, etag: this.announcementItem._etag }, this.announcementItem, message => {
        if (message.data.code === 1) {
          this.announcementDialog = false
          this.getNews()
        }
      }, error => {
        console.log(error)
      })
    },
    deleteAnnouncement () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      deleteNews({ tenantId: tenant.tenantId, siteId: site.siteId, id: this.announcementItem.id }, message => {
        if (message.data.code === 1) {
          this.deleteDialog = false
          this.getNews()
        }
      }, error => {
        console.log(error)
      })
    },
    async getNews () {
      try {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const news = await getNews({
          tenantId: tenant.tenantId,
          siteId: site.siteId,
          newsReceiver: this.receiver,
          groupId: this.groupId,
          newsType: this.newsType,
          offset: (itemsPerPage * (page - 1)),
          limit: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc
        })
        if (news.data.code === 1) {
        // console.log(news.data.result)
          this.items = news.data.result.items
          this.totalItems = news.data.result.itemsLength
        } else {
          this.items = []
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async fetchData () {
      if (this.$auth.role) {
        try {
          this.loading = true
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const site = JSON.parse(sessionStorage.getItem('Site'))
          const receiver = await getMasterNewsReceiver(
            {
              tenantId: (this.$auth.role.isSystemAdmin) ? null : tenant.tenantId,
              siteId: (this.$auth.role.isSystemAdmin) ? null : site.siteId
            })
          if (receiver.data.code === 1) {
            this.receiverList = receiver.data.result
            // if (!this.$auth.role.isSystemAdmin) this.receiverList.splice(this.receiverList.indexOf(this.receiverList.filter(e => e.id === 'all_user')[0]), 1)
            this.receiver = this.receiverList[0].id
          }
          const newsType = await getMasterNewsType(
            {
              tenantId: (this.$auth.role.isSystemAdmin) ? null : tenant.tenantId,
              siteId: (this.$auth.role.isSystemAdmin) ? null : site.siteId
            })
          if (newsType.data.code === 1) {
            this.newsTypeList = newsType.data.result
            this.newsType = this.newsTypeList[0].id
          }
          const group = await getGroup({
            tenantId: tenant.tenantId,
            siteId: site.siteId
          })
          if (group.data.code === 1) {
            this.groupList = group.data.result.items
          }
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          const news = await getNews({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            newsReceiver: this.receiver,
            groupId: this.groupId,
            newsType: this.newsType,
            offset: (itemsPerPage * (page - 1)),
            limit: itemsPerPage,
            sortBy: sortBy,
            sortDesc: sortDesc
          })
          if (news.data.code === 1) {
            // console.log(news.data.result)
            this.items = news.data.result.items
            this.totalItems = news.data.result.itemsLength
          } else {
            this.items = []
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.filter = true
          this.loading = false
        }
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeRouteLeave (to, from, next) {
    this.result = {}
    next()
  }
}
</script>

<style>

</style>
